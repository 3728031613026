* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background: #fff;
  /* background: url('../public/img/bg.png') no-repeat center;
  background-size: cover;  */
}
