h2 {
    margin-top: 10px;
    font-family: "Bangers", cursive;
    text-transform: uppercase;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
  
    background: linear-gradient(347.73deg, #35B700 8.96%, #25660E 120.05%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .info-img {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    text-align: center;
  }
  
  @media only screen and (max-width: 320px) {
    h2{
      font-size: 32px;
    }
  }