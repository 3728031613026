.container {
  padding: 0 20px;
}

header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-back {
  display: inline-block;
  line-height: 1;
  width: 12px;
  margin-right: 20px;
}
.btn-back span {
  color: #979797;
  font-size: 28px;
}

.img-banner {
  width: 100%;
}
.pointer {
  cursor: pointer !important;
}

.button-primary {
  background: #389e0d;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 12px 0;
  color: #ffffff;
}
.button-primary:hover {
  opacity: 0.8;
  cursor: pointer;
}
.bg-header {
  background: url("../public/img/bg-header-newsfeed.png") no-repeat left;
  background-size: auto 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}
.button-width {
  display: block;
  max-width: 300px;
  margin: 0 auto;
}
.info-member-confirm {
  color: #D82727;
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
}
.ant-modal-root .ant-modal-wrap,
.ant-modal-root .ant-modal-mask,
.ant-drawer,
.ant-drawer-mask{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ant-drawer-bottom>.ant-drawer-content-wrapper{
  left: 0;
  right: 0;
}
ul li::before {
  content: "";
}

ul li::marker {
  font-size: 18px;
  color: #FCB156;
  font-weight: bold; 
}

.div-ckeditor img {
  max-width: 100%;
}